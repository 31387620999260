<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="12"
	height="12"
	viewBox="0 0 12 12"
	fill="none"
>
	<g clip-path="url(#clip0_641_4096)">
		<path
			d="M9.33872 0.465904L8.38084 1.47763L10.586 3.80671L11.5432 2.79499C11.688 2.64207 11.8029 2.46052 11.8813 2.26071C11.9597 2.06089 12 1.84673 12 1.63045C12 1.41416 11.9597 1.2 11.8813 1.00018C11.8029 0.800369 11.688 0.61882 11.5432 0.465904C11.2461 0.166831 10.8514 0 10.441 0C10.0305 0 9.63584 0.166831 9.33872 0.465904Z"
			fill="#111928"
		/>
		<path
			d="M9.70416 4.73808L7.49904 2.409L4.55243 5.51862C4.46525 5.61052 4.4058 5.72764 4.38156 5.8552L4.05354 7.60201C4.03335 7.70838 4.03836 7.81836 4.0681 7.92219C4.09784 8.02602 4.15141 8.12049 4.22403 8.19719C4.29666 8.2739 4.38609 8.33047 4.4844 8.36189C4.5827 8.3933 4.68683 8.39859 4.78754 8.37727L6.44138 8.02817C6.56192 8.00245 6.67258 7.93966 6.75942 7.8477L9.70416 4.73808Z"
			fill="#111928"
		/>
		<path
			d="M4.55243 5.51862C4.46525 5.61052 4.4058 5.72764 4.38156 5.8552L4.05354 7.60201C4.03335 7.70838 4.03836 7.81836 4.0681 7.92219C4.09784 8.02602 4.15141 8.12049 4.22403 8.19719C4.29666 8.2739 4.38609 8.33047 4.4844 8.36189C4.5827 8.3933 4.68683 8.39859 4.78754 8.37727L6.44138 8.02817C6.56192 8.00245 6.67258 7.93966 6.75942 7.8477L9.70416 4.73808L7.49904 2.409L4.55243 5.51862Z"
			fill="#111928"
		/>
		<path
			d="M7.35436 8.61704C7.18011 8.80076 6.9584 8.92609 6.71702 8.97733L4.51189 9.44302C4.31059 9.48548 4.10248 9.47482 3.90602 9.41196C3.70956 9.3491 3.53082 9.236 3.38566 9.08269C3.24051 8.92938 3.13343 8.74059 3.07392 8.53309C3.01441 8.32558 3.00431 8.10577 3.04452 7.89315L3.48542 5.56407C3.53393 5.30911 3.65259 5.07494 3.82654 4.8909L6.89475 1.65021C6.87768 1.5888 6.86887 1.52517 6.86856 1.46117H1.35139C0.993077 1.46152 0.649539 1.61201 0.396176 1.87962C0.142814 2.14722 0.000330238 2.51007 0 2.88852V10.5726C0.000330238 10.9511 0.142814 11.3139 0.396176 11.5816C0.649539 11.8492 0.993077 11.9997 1.35139 12H8.62654C8.98485 11.9997 9.32839 11.8492 9.58175 11.5816C9.83512 11.3139 9.9776 10.9511 9.97793 10.5726V5.84598L7.35436 8.61704Z"
			fill="#111928"
		/>
	</g>
	<defs>
		<clipPath id="clip0_641_4096">
			<rect width="12" height="12" fill="white" />
		</clipPath>
	</defs>
</svg>
