import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-new-badge-svg',
	standalone: true,
	imports: [],
	templateUrl: './new-badge-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewBadgeSvgComponent {}
