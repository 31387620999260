<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="7.25 2.95 9.5 18"
	stroke="currentColor"
	class="w-2.5 h-4"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke="currentColor"
		stroke-width="2.4"
		d="M15.75 19.5L8.25 12l7.5-7.5"
	></path>
</svg>
