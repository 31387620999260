<svg
	xmlns="http://www.w3.org/2000/svg"
	width="28"
	height="28"
	viewBox="0 0 28 28"
	fill="none"
>
	<g filter="url(#filter0_d_5407_41979)">
		<g clip-path="url(#clip0_5407_41979)">
			<path
				d="M16 6L23 13M23 13L16 20M23 13L5 13"
				stroke="#111827"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</g>
	<defs>
		<filter
			id="filter0_d_5407_41979"
			x="0"
			y="0"
			width="28"
			height="28"
			filterUnits="userSpaceOnUse"
			color-interpolation-filters="sRGB"
		>
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feColorMatrix
				in="SourceAlpha"
				type="matrix"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				result="hardAlpha"
			/>
			<feOffset dy="1" />
			<feGaussianBlur stdDeviation="1" />
			<feColorMatrix
				type="matrix"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
			/>
			<feBlend
				mode="normal"
				in2="BackgroundImageFix"
				result="effect1_dropShadow_5407_41979"
			/>
			<feBlend
				mode="normal"
				in="SourceGraphic"
				in2="effect1_dropShadow_5407_41979"
				result="shape"
			/>
		</filter>
		<clipPath id="clip0_5407_41979">
			<rect x="2" y="1" width="24" height="24" rx="8" fill="white" />
		</clipPath>
	</defs>
</svg>
