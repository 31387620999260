<svg
	width="16"
	height="17"
	viewBox="0 0 16 17"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M0.799988 6.0998V14.0998C0.799988 14.5241 0.968559 14.9311 1.26862 15.2312C1.56868 15.5312 1.97564 15.6998 2.39999 15.6998H13.6C14.0243 15.6998 14.4313 15.5312 14.7314 15.2312C15.0314 14.9311 15.2 14.5241 15.2 14.0998V6.0998H0.799988ZM11.5656 11.4654L9.56559 13.4654C9.41471 13.6111 9.21262 13.6918 9.00287 13.6899C8.79311 13.6881 8.59246 13.604 8.44413 13.4557C8.29581 13.3073 8.21167 13.1067 8.20985 12.8969C8.20803 12.6872 8.28866 12.4851 8.43439 12.3342L9.06879 11.6998H4.79999C4.58781 11.6998 4.38433 11.6155 4.2343 11.4655C4.08427 11.3155 3.99999 11.112 3.99999 10.8998C3.99999 10.6876 4.08427 10.4841 4.2343 10.3341C4.38433 10.1841 4.58781 10.0998 4.79999 10.0998H9.06879L8.43439 9.4654C8.28866 9.31452 8.20803 9.11244 8.20985 8.90268C8.21167 8.69293 8.29581 8.49228 8.44413 8.34395C8.59246 8.19562 8.79311 8.11149 9.00287 8.10967C9.21262 8.10784 9.41471 8.18848 9.56559 8.3342L11.5656 10.3342C11.7156 10.4842 11.7998 10.6877 11.7998 10.8998C11.7998 11.1119 11.7156 11.3154 11.5656 11.4654Z"
		fill="#6B7280"
	/>
	<path
		d="M8.03439 1.9398C7.88497 1.7415 7.69163 1.58052 7.46955 1.46948C7.24747 1.35844 7.00268 1.30036 6.75439 1.2998H2.39999C1.97564 1.2998 1.56868 1.46838 1.26862 1.76843C0.968559 2.06849 0.799988 2.47546 0.799988 2.8998V4.4998H9.95439L8.03439 1.9398Z"
		fill="#6B7280"
	/>
</svg>
