<svg
	class="w-5 h-5 text-gray-400"
	[ngClass]="customStyles"
	viewBox="0 0 20 20"
	fill="currentColor"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M13.3668 0H9.91398V13.6232C9.91398 15.2464 8.58602 16.5797 6.9334 16.5797C5.28077 16.5797 3.95278 15.2464 3.95278 13.6232C3.95278 12.029 5.25127 10.7246 6.84488 10.6667V7.24639C3.33305 7.30433 0.5 10.1159 0.5 13.6232C0.5 17.1594 3.39207 20 6.96292 20C10.5337 20 13.4258 17.1304 13.4258 13.6232V6.63767C14.7243 7.56522 16.3179 8.11594 18 8.14494V4.72464C15.4031 4.63768 13.3668 2.55072 13.3668 0Z"
	/>
</svg>
