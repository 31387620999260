import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-page-not-found-img-svg',
	standalone: true,
	imports: [],
	templateUrl: './page-not-found-img-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundImgSvgComponent {}
