<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M9.99998 4.99992V3.33325M9.99998 4.99992C9.0795 4.99992 8.33331 5.74611 8.33331 6.66658C8.33331 7.58706 9.0795 8.33325 9.99998 8.33325M9.99998 4.99992C10.9205 4.99992 11.6666 5.74611 11.6666 6.66658C11.6666 7.58706 10.9205 8.33325 9.99998 8.33325M4.99998 14.9999C5.92045 14.9999 6.66665 14.2537 6.66665 13.3333C6.66665 12.4128 5.92045 11.6666 4.99998 11.6666M4.99998 14.9999C4.0795 14.9999 3.33331 14.2537 3.33331 13.3333C3.33331 12.4128 4.0795 11.6666 4.99998 11.6666M4.99998 14.9999V16.6666M4.99998 11.6666V3.33325M9.99998 8.33325V16.6666M15 14.9999C15.9205 14.9999 16.6666 14.2537 16.6666 13.3333C16.6666 12.4128 15.9205 11.6666 15 11.6666M15 14.9999C14.0795 14.9999 13.3333 14.2537 13.3333 13.3333C13.3333 12.4128 14.0795 11.6666 15 11.6666M15 14.9999V16.6666M15 11.6666V3.33325"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
