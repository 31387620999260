<svg
	class="w-5 h-5 text-gray-500"
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	fill="currentColor"
>
	<g clip-path="url(#a)">
		<g clip-path="url(#b)">
			<path
				d="M10.005 2.485c2.451 0 2.741.01 3.705.054.896.04 1.38.19 1.702.315.426.165.734.366 1.053.685.323.322.52.627.685 1.053.125.322.276.81.315 1.702.043.967.054 1.257.054 3.704 0 2.451-.011 2.741-.054 3.705-.04.896-.19 1.38-.315 1.702a2.838 2.838 0 0 1-.685 1.053 2.82 2.82 0 0 1-1.053.684c-.322.126-.81.276-1.702.316-.967.043-1.257.054-3.705.054-2.45 0-2.74-.011-3.704-.054-.896-.04-1.38-.19-1.702-.316a2.836 2.836 0 0 1-1.053-.684 2.82 2.82 0 0 1-.685-1.053c-.125-.323-.276-.81-.315-1.702-.043-.967-.054-1.258-.054-3.705 0-2.45.011-2.74.054-3.704.04-.896.19-1.38.315-1.702.165-.426.366-.735.685-1.053.322-.323.627-.52 1.053-.685.322-.125.81-.276 1.702-.315.964-.043 1.254-.054 3.704-.054Zm0-1.652c-2.49 0-2.801.011-3.78.054-.974.043-1.644.2-2.224.427a4.477 4.477 0 0 0-1.627 1.06 4.494 4.494 0 0 0-1.06 1.623c-.226.584-.384 1.25-.427 2.225-.043.982-.054 1.293-.054 3.783s.011 2.802.054 3.78c.043.975.2 1.645.427 2.225a4.477 4.477 0 0 0 1.06 1.627 4.484 4.484 0 0 0 1.623 1.057c.584.226 1.25.383 2.225.426.978.043 1.29.054 3.78.054 2.49 0 2.802-.01 3.78-.054.974-.043 1.644-.2 2.225-.426a4.485 4.485 0 0 0 1.623-1.057 4.483 4.483 0 0 0 1.057-1.623c.225-.584.383-1.25.426-2.225.043-.978.054-1.29.054-3.78 0-2.49-.01-2.802-.054-3.78-.043-.974-.2-1.644-.426-2.225a4.296 4.296 0 0 0-1.05-1.63 4.483 4.483 0 0 0-1.623-1.057c-.584-.226-1.25-.383-2.225-.426-.982-.047-1.293-.058-3.784-.058Z"
			/>
			<path
				d="M10.005 5.294a4.713 4.713 0 0 0 0 9.423 4.713 4.713 0 0 0 0-9.423Zm0 7.768a3.057 3.057 0 1 1 .002-6.114 3.057 3.057 0 0 1-.002 6.114ZM16.003 5.108a1.1 1.1 0 1 1-2.2 0 1.1 1.1 0 0 1 2.2 0Z"
			/>
		</g>
	</g>
	<defs>
		<clipPath id="a">
			<rect width="20" height="20" fill="#fff" rx="8" />
		</clipPath>
		<clipPath id="b">
			<path fill="#fff" d="M0 0h20v20H0z" />
		</clipPath>
	</defs>
</svg>
