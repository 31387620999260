<svg
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
		fill="#1D9BF0"
	/>
	<path
		d="M16.3387 30.5502C25.2087 30.5502 30.0588 23.2002 30.0588 16.8302C30.0588 16.6202 30.0587 16.4102 30.0487 16.2102C30.9887 15.5302 31.8088 14.6802 32.4587 13.7102C31.5987 14.0902 30.6687 14.3502 29.6887 14.4702C30.6887 13.8702 31.4487 12.9302 31.8088 11.8002C30.8787 12.3502 29.8487 12.7502 28.7487 12.9702C27.8687 12.0302 26.6187 11.4502 25.2288 11.4502C22.5687 11.4502 20.4088 13.6102 20.4088 16.2702C20.4088 16.6502 20.4487 17.0202 20.5387 17.3702C16.5287 17.1702 12.9787 15.2502 10.5988 12.3302C10.1888 13.0402 9.94875 13.8702 9.94875 14.7502C9.94875 16.4202 10.7988 17.9002 12.0988 18.7602C11.3088 18.7402 10.5687 18.5202 9.91875 18.1602C9.91875 18.1802 9.91875 18.2002 9.91875 18.2202C9.91875 20.5602 11.5787 22.5002 13.7887 22.9502C13.3887 23.0602 12.9587 23.1202 12.5187 23.1202C12.2087 23.1202 11.9088 23.0902 11.6088 23.0302C12.2188 24.9502 13.9988 26.3402 16.1088 26.3802C14.4588 27.6702 12.3787 28.4402 10.1187 28.4402C9.72875 28.4402 9.34875 28.4202 8.96875 28.3702C11.0787 29.7502 13.6187 30.5502 16.3387 30.5502Z"
		fill="white"
	/>
</svg>
