import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-hide-password-svg',
	standalone: true,
	imports: [],
	templateUrl: './hide-password-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HidePasswordSvgComponent {}
