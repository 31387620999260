import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-book-svg',
	standalone: true,
	imports: [],
	templateUrl: './book-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookSvgComponent {}
