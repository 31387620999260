<svg
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_2_14517)">
		<path
			d="M14.4 0.666748H1.6C1.17565 0.666748 0.768687 0.838441 0.468629 1.14406C0.168571 1.44967 0 1.86417 0 2.29638V13.7038C0 14.136 0.168571 14.5505 0.468629 14.8561C0.768687 15.1617 1.17565 15.3334 1.6 15.3334H14.4C14.8243 15.3334 15.2313 15.1617 15.5314 14.8561C15.8314 14.5505 16 14.136 16 13.7038V2.29638C16 1.86417 15.8314 1.44967 15.5314 1.14406C15.2313 0.838441 14.8243 0.666748 14.4 0.666748ZM10 3.92601C10.2373 3.92601 10.4693 3.99769 10.6667 4.13199C10.864 4.26629 11.0178 4.45717 11.1087 4.68051C11.1995 4.90384 11.2232 5.14959 11.1769 5.38667C11.1306 5.62376 11.0164 5.84154 10.8485 6.01247C10.6807 6.1834 10.4669 6.29981 10.2341 6.34697C10.0013 6.39413 9.76005 6.36992 9.54078 6.27742C9.32151 6.18491 9.13409 6.02825 9.00224 5.82726C8.87038 5.62627 8.8 5.38996 8.8 5.14823C8.8 4.82408 8.92643 4.5132 9.15147 4.28399C9.37652 4.05478 9.68174 3.92601 10 3.92601ZM13.5008 12.4661C13.4319 12.594 13.3305 12.7008 13.2072 12.7752C13.0839 12.8496 12.9433 12.8889 12.8 12.889H3.2C3.06362 12.889 2.92949 12.8536 2.81034 12.786C2.6912 12.7184 2.591 12.6209 2.51925 12.5028C2.44751 12.3846 2.40662 12.2498 2.40045 12.111C2.39428 11.9723 2.42304 11.8342 2.484 11.7099L5.284 6.00623C5.34846 5.87467 5.44666 5.76332 5.56821 5.68399C5.68976 5.60466 5.83011 5.56031 5.9744 5.55564C6.11993 5.54739 6.26478 5.58128 6.3922 5.65337C6.51962 5.72547 6.62439 5.83283 6.6944 5.96304L8.9144 9.83912L10.1512 8.30156C10.2315 8.20191 10.3339 8.12309 10.45 8.07159C10.5661 8.0201 10.6925 7.9974 10.8188 8.00535C10.9452 8.0133 11.0679 8.05169 11.1769 8.11735C11.2858 8.18301 11.3779 8.27407 11.4456 8.38304L13.4784 11.6423C13.5537 11.7652 13.5955 11.9063 13.5994 12.0511C13.6033 12.1959 13.5693 12.3391 13.5008 12.4661Z"
			fill="#9CA3AF"
		/>
	</g>
	<defs>
		<clipPath id="clip0_2_14517">
			<rect width="16" height="16" fill="white" />
		</clipPath>
	</defs>
</svg>
