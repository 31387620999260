<svg
	class="w-4 h-4 fill-gray-900"
	[ngClass]="customStyles"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M14.6668 11.2V1.6C14.6668 1.17565 14.4912 0.768687 14.1787 0.468629C13.8661 0.168571 13.4422 0 13.0002 0H3.00016C2.55814 0 2.13421 0.168571 1.82165 0.468629C1.50909 0.768687 1.3335 1.17565 1.3335 1.6V13.6C1.3335 14.2365 1.59689 14.847 2.06573 15.2971C2.53457 15.7471 3.17045 16 3.8335 16H13.8335C14.0545 16 14.2665 15.9157 14.4228 15.7657C14.579 15.6157 14.6668 15.4122 14.6668 15.2C14.6668 14.9878 14.579 14.7843 14.4228 14.6343C14.2665 14.4843 14.0545 14.4 13.8335 14.4H13.0002V12.8C13.4422 12.8 13.8661 12.6314 14.1787 12.3314C14.4912 12.0313 14.6668 11.6243 14.6668 11.2ZM4.66683 1.6H6.3335V11.2H4.66683V1.6ZM11.3335 14.4H3.8335C3.61248 14.4 3.40052 14.3157 3.24424 14.1657C3.08796 14.0157 3.00016 13.8122 3.00016 13.6C3.00016 13.3878 3.08796 13.1843 3.24424 13.0343C3.40052 12.8843 3.61248 12.8 3.8335 12.8H11.3335V14.4Z"
		fill="fill-inherit"
	/>
</svg>
