<svg
	class="w-4 h-4"
	[ngClass]="customStyles()"
	viewBox="0 0 14 14"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_17_47733)">
		<path
			d="M11.9908 0.7H9.65369C9.52953 0.487871 9.35074 0.311529 9.13525 0.188666C8.91976 0.0658023 8.67515 0.000737761 8.42597 0H5.57411C5.32493 0.000737761 5.08032 0.0658023 4.86483 0.188666C4.64934 0.311529 4.47055 0.487871 4.34639 0.7H2.0093C1.63112 0.7 1.26843 0.847499 1.00102 1.11005C0.733605 1.3726 0.583374 1.7287 0.583374 2.1V12.6C0.583374 12.9713 0.733605 13.3274 1.00102 13.5899C1.26843 13.8525 1.63112 14 2.0093 14H11.9908C12.369 14 12.7317 13.8525 12.9991 13.5899C13.2665 13.3274 13.4167 12.9713 13.4167 12.6V2.1C13.4167 1.7287 13.2665 1.3726 12.9991 1.11005C12.7317 0.847499 12.369 0.7 11.9908 0.7ZM8.42597 1.4V2.8H5.57411V1.4H8.42597ZM11.9908 12.6H2.0093V2.1H4.14819V2.8C3.9591 2.8 3.77775 2.87375 3.64405 3.00503C3.51034 3.1363 3.43523 3.31435 3.43523 3.5C3.43523 3.68565 3.51034 3.8637 3.64405 3.99497C3.77775 4.12625 3.9591 4.2 4.14819 4.2H9.85189C10.041 4.2 10.2223 4.12625 10.356 3.99497C10.4897 3.8637 10.5649 3.68565 10.5649 3.5C10.5649 3.31435 10.4897 3.1363 10.356 3.00503C10.2223 2.87375 10.041 2.8 9.85189 2.8V2.1H11.9908V12.6Z"
			fill="currentColor"
		/>
		<path
			d="M6.30419 10.1003C6.11774 10.1014 5.93837 10.0302 5.80512 9.9022L4.36208 8.5274C4.29485 8.46305 4.2412 8.38633 4.20417 8.30163C4.16714 8.21692 4.14747 8.12588 4.14628 8.03371C4.14509 7.94154 4.1624 7.85004 4.19722 7.76444C4.23205 7.67883 4.2837 7.6008 4.34924 7.5348C4.41478 7.4688 4.49292 7.41612 4.5792 7.37976C4.66547 7.34341 4.7582 7.3241 4.85208 7.32293C4.94596 7.32176 5.03915 7.33875 5.12634 7.37294C5.21353 7.40713 5.293 7.45785 5.36023 7.5222L6.30704 8.4245L8.64556 6.1978C8.71209 6.1308 8.79162 6.07758 8.87946 6.04128C8.96729 6.00498 9.06165 5.98634 9.15695 5.98646C9.25226 5.98658 9.34657 6.00545 9.43431 6.04197C9.52205 6.07849 9.60145 6.13191 9.6678 6.19907C9.73416 6.26623 9.78613 6.34577 9.82063 6.43299C9.85514 6.52021 9.87149 6.61333 9.86871 6.70686C9.86594 6.80039 9.84409 6.89242 9.80446 6.97752C9.76483 7.06262 9.70823 7.13905 9.638 7.2023L6.80398 9.9022C6.6702 10.0299 6.49077 10.101 6.30419 10.1003Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_17_47733">
			<rect fill="white" />
		</clipPath>
	</defs>
</svg>
