import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-exclamation-mark-triangular-svg',
	standalone: true,
	imports: [],
	templateUrl: './exclamation-mark-triangular-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclamationMarkTriangularSvgComponent {
	@Input() customStyles = '';
}
