import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'uc-calendar-month-outline-svg',
	standalone: true,
	templateUrl: './calendar-month-outline-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class CalendarMonthOutlineSvgComponent {
	customStyles = input('text-gray-500');
}
