<svg
	fill="none"
	width="20"
	height="20"
	viewBox="0 0 20 20"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M11.6667 8.33333H15.6366C16.8756 8.33333 17.6814 9.63719 17.1273 10.7454L14.2107 16.5787C13.9283 17.1433 13.3512 17.5 12.7199 17.5H9.37184C9.23557 17.5 9.09982 17.4833 8.96762 17.4502L5.83333 16.6667M11.6667 8.33333V4.16667C11.6667 3.24619 10.9205 2.5 10 2.5H9.92044C9.50414 2.5 9.16667 2.83748 9.16667 3.25377C9.16667 3.84903 8.99047 4.43096 8.66028 4.92624L5.83333 9.16667V16.6667M11.6667 8.33333H10M5.83333 16.6667H4.16667C3.24619 16.6667 2.5 15.9205 2.5 15V10C2.5 9.07953 3.24619 8.33333 4.16667 8.33333H6.25"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
