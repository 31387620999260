import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-exclamation-mark-triangular-round-bg-svg',
	standalone: true,
	imports: [],
	templateUrl: './exclamation-mark-triangular-round-bg-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclamationMarkTriangularRoundBgSvgComponent {}
