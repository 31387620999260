import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-arrow-left-svg',
	standalone: true,
	templateUrl: './arrow-left-svg.component.html',
	imports: [NgClass],
})
export class ArrowLeftSvgComponent {
	@Input() customStyles = '';
}
