<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
>
	<path
		d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0V0ZM7.6 3.2C7.83734 3.2 8.06935 3.27038 8.26669 3.40224C8.46402 3.53409 8.61783 3.72151 8.70866 3.94078C8.79948 4.16005 8.82325 4.40133 8.77694 4.63411C8.73064 4.86688 8.61635 5.0807 8.44853 5.24853C8.28071 5.41635 8.06689 5.53064 7.83411 5.57694C7.60133 5.62324 7.36005 5.59948 7.14078 5.50865C6.92151 5.41783 6.7341 5.26402 6.60224 5.06668C6.47038 4.86934 6.4 4.63734 6.4 4.4C6.4 4.08174 6.52643 3.77651 6.75147 3.55147C6.97652 3.32643 7.28174 3.2 7.6 3.2ZM9.6 12H6.4C6.18783 12 5.98435 11.9157 5.83432 11.7657C5.68429 11.6157 5.6 11.4122 5.6 11.2C5.6 10.9878 5.68429 10.7843 5.83432 10.6343C5.98435 10.4843 6.18783 10.4 6.4 10.4H7.2V8H6.4C6.18783 8 5.98435 7.91571 5.83432 7.76568C5.68429 7.61565 5.6 7.41217 5.6 7.2C5.6 6.98782 5.68429 6.78434 5.83432 6.63431C5.98435 6.48428 6.18783 6.4 6.4 6.4H8C8.21218 6.4 8.41566 6.48428 8.56569 6.63431C8.71572 6.78434 8.8 6.98782 8.8 7.2V10.4H9.6C9.81217 10.4 10.0157 10.4843 10.1657 10.6343C10.3157 10.7843 10.4 10.9878 10.4 11.2C10.4 11.4122 10.3157 11.6157 10.1657 11.7657C10.0157 11.9157 9.81217 12 9.6 12Z"
		fill="#6B7280"
	/>
</svg>
