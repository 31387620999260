<svg
	xmlns="http://www.w3.org/2000/svg"
	width="13"
	height="12"
	viewBox="0 0 13 12"
	fill="none"
>
	<path
		d="M5.5999 1.2002C5.28164 1.2002 4.97642 1.32662 4.75137 1.55167C4.52633 1.77671 4.3999 2.08194 4.3999 2.4002V7.20019C4.3999 7.51845 4.52633 7.82368 4.75137 8.04872C4.97642 8.27377 5.28164 8.40019 5.5999 8.40019H9.1999C9.51816 8.40019 9.82339 8.27377 10.0484 8.04872C10.2735 7.82368 10.3999 7.51845 10.3999 7.20019V3.8486C10.3998 3.53036 10.2734 3.22519 10.0483 3.0002L8.5999 1.5518C8.37491 1.32674 8.06974 1.20026 7.7515 1.2002H5.5999Z"
		fill="#111928"
	/>
	<path
		d="M2 4.8001C2 4.48184 2.12643 4.17661 2.35147 3.95157C2.57652 3.72653 2.88174 3.6001 3.2 3.6001V9.6001H8C8 9.91836 7.87357 10.2236 7.64853 10.4486C7.42348 10.6737 7.11826 10.8001 6.8 10.8001H3.2C2.88174 10.8001 2.57652 10.6737 2.35147 10.4486C2.12643 10.2236 2 9.91836 2 9.6001V4.8001Z"
		fill="#111928"
	/>
</svg>
