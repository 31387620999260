<svg
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_2_14531)">
		<path
			d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM8 12C7.84178 12 7.68711 11.9531 7.55555 11.8652C7.42399 11.7773 7.32145 11.6523 7.2609 11.5061C7.20035 11.36 7.18451 11.1991 7.21537 11.0439C7.24624 10.8887 7.32243 10.7462 7.43432 10.6343C7.5462 10.5224 7.68875 10.4462 7.84393 10.4154C7.99911 10.3845 8.15997 10.4003 8.30615 10.4609C8.45233 10.5214 8.57727 10.624 8.66518 10.7555C8.75308 10.8871 8.8 11.0418 8.8 11.2C8.8 11.4122 8.71572 11.6157 8.56569 11.7657C8.41566 11.9157 8.21218 12 8 12ZM8.8 8.8C8.8 9.01217 8.71572 9.21565 8.56569 9.36568C8.41566 9.51571 8.21218 9.6 8 9.6C7.78783 9.6 7.58435 9.51571 7.43432 9.36568C7.28429 9.21565 7.2 9.01217 7.2 8.8V4.8C7.2 4.58783 7.28429 4.38434 7.43432 4.23431C7.58435 4.08428 7.78783 4 8 4C8.21218 4 8.41566 4.08428 8.56569 4.23431C8.71572 4.38434 8.8 4.58783 8.8 4.8V8.8Z"
			fill="#9CA3AF"
		/>
	</g>
	<defs>
		<clipPath id="clip0_2_14531">
			<rect width="16" height="16" fill="white" />
		</clipPath>
	</defs>
</svg>
