import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-user-icon-svg',
	imports: [CommonModule],
	standalone: true,
	templateUrl: './user-icon-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconSvgComponent {
	@Input() customStyles = '';
}
