<svg
	class="w-4 h-4"
	[ngClass]="customStyles()"
	viewBox="0 0 12 13"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_89_514)">
		<path
			d="M8.8242 4.988C8.71168 4.87263 8.5591 4.80782 8.4 4.80782C8.2409 4.80782 8.08832 4.87263 7.9758 4.988L6.6 6.39908V1.11538C6.6 0.952174 6.53679 0.795649 6.42426 0.680242C6.31174 0.564835 6.15913 0.5 6 0.5C5.84087 0.5 5.68826 0.564835 5.57574 0.680242C5.46321 0.795649 5.4 0.952174 5.4 1.11538V6.39908L4.0242 4.988C3.96885 4.92922 3.90265 4.88234 3.82944 4.85009C3.75624 4.81784 3.67751 4.80086 3.59784 4.80015C3.51817 4.79944 3.43917 4.81501 3.36543 4.84596C3.29169 4.8769 3.2247 4.92259 3.16836 4.98037C3.11203 5.03815 3.06748 5.10686 3.03731 5.18249C3.00714 5.25812 2.99196 5.33915 2.99265 5.42086C2.99334 5.50257 3.00989 5.58332 3.04134 5.6584C3.07278 5.73348 3.11849 5.80139 3.1758 5.85815L5.5758 8.31969C5.63154 8.377 5.69775 8.42247 5.77064 8.45349C5.84353 8.48452 5.92168 8.50049 6.0006 8.50049C6.07952 8.50049 6.15767 8.48452 6.23056 8.45349C6.30345 8.42247 6.36967 8.377 6.4254 8.31969L8.8254 5.85815C8.93772 5.74259 9.0007 5.586 9.00047 5.42282C9.00025 5.25964 8.93684 5.10324 8.8242 4.988Z"
			fill="white"
		/>
		<path
			d="M10.8 7.57692H9.27L7.485 9.40769C7.28999 9.60772 7.05848 9.76639 6.80369 9.87464C6.54889 9.9829 6.27579 10.0386 6 10.0386C5.72421 10.0386 5.45111 9.9829 5.19632 9.87464C4.94152 9.76639 4.71001 9.60772 4.515 9.40769L2.73 7.57692H1.2C0.88174 7.57692 0.576515 7.70659 0.351472 7.93741C0.126428 8.16822 0 8.48127 0 8.80769V11.2692C0 11.5957 0.126428 11.9087 0.351472 12.1395C0.576515 12.3703 0.88174 12.5 1.2 12.5H10.8C11.1183 12.5 11.4235 12.3703 11.6485 12.1395C11.8736 11.9087 12 11.5957 12 11.2692V8.80769C12 8.48127 11.8736 8.16822 11.6485 7.93741C11.4235 7.70659 11.1183 7.57692 10.8 7.57692ZM9.3 11.2692C9.122 11.2692 8.94799 11.2151 8.79999 11.1137C8.65198 11.0122 8.53663 10.8681 8.46851 10.6994C8.40039 10.5307 8.38257 10.3451 8.41729 10.1661C8.45202 9.98701 8.53774 9.82254 8.6636 9.69344C8.78947 9.56435 8.94984 9.47643 9.12442 9.44081C9.299 9.4052 9.47996 9.42348 9.64441 9.49334C9.80887 9.56321 9.94943 9.68152 10.0483 9.83332C10.1472 9.98512 10.2 10.1636 10.2 10.3462C10.2 10.591 10.1052 10.8258 9.9364 10.9989C9.76761 11.172 9.53869 11.2692 9.3 11.2692Z"
			fill="white"
		/>
	</g>
	<defs>
		<clipPath id="clip0_89_514">
			<rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
		</clipPath>
	</defs>
</svg>
