import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-instagram-outline-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './instagram-outline-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstagramOutlineSvgComponent {
	@Input() customStyles = '';
}
