import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-twitter-v2-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './twitter-v2-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwitterV2SvgComponent {
	@Input() customStyles = '';
}
