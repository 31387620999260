<svg
	class="w-5 h-5 fill-gray-500"
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 16 17"
>
	<g clip-path="url(#a)">
		<path
			d="M14.518 3.868H11.26V2.184c0-.446-.171-.875-.477-1.19A1.603 1.603 0 0 0 9.63.5H6.37c-.432 0-.846.177-1.152.493a1.713 1.713 0 0 0-.477 1.191v1.684H1.48a.802.802 0 0 0-.576.247.857.857 0 0 0-.238.596c0 .223.086.437.238.595.153.158.36.247.577.247h.814v9.263c0 .446.172.875.478 1.19.305.317.72.494 1.152.494h8.148c.432 0 .847-.177 1.152-.493.306-.316.478-.745.478-1.191V5.553h.814a.802.802 0 0 0 .577-.247.857.857 0 0 0 .238-.595.857.857 0 0 0-.238-.596.802.802 0 0 0-.577-.247ZM6.37 2.184h3.26v1.684H6.37V2.184Zm.815 10.948a.857.857 0 0 1-.238.595.801.801 0 0 1-.577.247.801.801 0 0 1-.576-.247.857.857 0 0 1-.238-.595V7.237c0-.224.085-.438.238-.596a.802.802 0 0 1 .576-.246c.216 0 .424.088.577.246a.857.857 0 0 1 .238.596v5.895Zm3.26 0a.857.857 0 0 1-.24.595.801.801 0 0 1-.575.247.801.801 0 0 1-.577-.247.857.857 0 0 1-.238-.595V7.237c0-.224.086-.438.238-.596a.802.802 0 0 1 .577-.246c.216 0 .423.088.576.246a.857.857 0 0 1 .239.596v5.895Z"
		/>
	</g>
	<defs>
		<clipPath id="a">
			<path fill="#fff" d="M0 .5h16v16H0z" />
		</clipPath>
	</defs>
</svg>
