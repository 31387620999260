<svg
	class="w-5 h-5"
	viewBox="0 0 20 20"
	[ngClass]="customStyles()"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M5.83333 13.3333V3.33325M5.83333 3.33325L2.5 6.66658M5.83333 3.33325L9.16667 6.66658M14.1667 6.66658V16.6666M14.1667 16.6666L17.5 13.3333M14.1667 16.6666L10.8333 13.3333"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
