import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-ringed-tick-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './ringed-tick-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RingedTickSvgComponent {
	@Input() customStyles = 'h-6 w-6 mt-0.5 mr-2';
}
