<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	stroke-width="2.5"
	stroke="currentColor"
	width="16"
	height="16"
	class="text-gray-500"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
	/>
</svg>
