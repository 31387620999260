import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-arrow-right-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './arrow-right-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowRightSvgComponent {
	@Input() customStyles = '';
}
