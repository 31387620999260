<svg
	width="21"
	height="19"
	viewBox="0 0 21 19"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M10.5 11L19.5 6L10.5 1L1.5 6L10.5 11ZM10.5 11L16.6591 7.57826C17.2017 8.94659 17.5 10.4384 17.5 11.9999C17.5 12.7013 17.4398 13.3885 17.3244 14.0569C14.7143 14.3106 12.349 15.4006 10.5 17.0555C8.65096 15.4006 6.28571 14.3106 3.67562 14.0569C3.56017 13.3885 3.5 12.7012 3.5 11.9999C3.5 10.4384 3.79824 8.94656 4.34088 7.57825L10.5 11ZM6.5 16.9999V9.5L10.5 7.27778"
		stroke="white"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
