<svg
	width="57"
	height="56"
	viewBox="0 0 57 56"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="4.5" y="4" width="48" height="48" rx="24" fill="#DEF7EC" />
	<rect
		x="4.5"
		y="4"
		width="48"
		height="48"
		rx="24"
		stroke="#F3FAF7"
		stroke-width="8"
	/>
	<path
		d="M25.5001 27.9996L27.5001 29.9996L31.5001 25.9996M24.3349 20.6967C25.0524 20.6394 25.7336 20.3573 26.2814 19.8904C27.5599 18.8009 29.4403 18.8009 30.7188 19.8904C31.2667 20.3573 31.9478 20.6394 32.6654 20.6967C34.3398 20.8303 35.6695 22.1599 35.8031 23.8344C35.8603 24.5519 36.1425 25.2331 36.6094 25.781C37.6989 27.0594 37.6989 28.9398 36.6094 30.2183C36.1425 30.7662 35.8603 31.4474 35.8031 32.1649C35.6695 33.8393 34.3398 35.169 32.6654 35.3026C31.9479 35.3598 31.2667 35.642 30.7188 36.1089C29.4403 37.1984 27.5599 37.1984 26.2814 36.1089C25.7336 35.642 25.0524 35.3598 24.3349 35.3026C22.6604 35.169 21.3308 33.8393 21.1972 32.1649C21.1399 31.4474 20.8578 30.7662 20.3909 30.2183C19.3014 28.9398 19.3014 27.0594 20.3909 25.781C20.8578 25.2331 21.1399 24.5519 21.1972 23.8344C21.3308 22.1599 22.6604 20.8303 24.3349 20.6967Z"
		stroke="#046C4E"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
