<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="20"
	height="15"
	viewBox="0 0 21 16"
	fill="currentColor"
	stroke="currentColor"
>
	<path
		d="M0.50375 2.855L10.5 7.8525L20.4962 2.855C20.4592 2.21805 20.1801 1.61936 19.716 1.18153C19.2519 0.743707 18.638 0.499883 18 0.5H3C2.36198 0.499883 1.74806 0.743707 1.28397 1.18153C0.819885 1.61936 0.540755 2.21805 0.50375 2.855Z"
	/>
	<path
		d="M20.5 5.6475L10.5 10.6475L0.5 5.6475V13C0.5 13.663 0.763392 14.2989 1.23223 14.7678C1.70107 15.2366 2.33696 15.5 3 15.5H18C18.663 15.5 19.2989 15.2366 19.7678 14.7678C20.2366 14.2989 20.5 13.663 20.5 13V5.6475Z"
	/>
</svg>
