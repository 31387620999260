<svg
	width="56"
	height="56"
	viewBox="0 0 56 56"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
	<path
		d="M33.6569 34.6568C30.5327 37.781 25.4673 37.781 22.3431 34.6568C20.7811 33.0947 20 31.0474 20 29C20 26.9526 20.781 24.9052 22.3431 23.3431C22.3431 23.3431 23 25 25 26C25 24 25.5 21 27.9859 19C30 21 32.0912 21.7774 33.6569 23.3431C35.219 24.9052 36 26.9526 36 29C36 31.0474 35.2189 33.0947 33.6569 34.6568Z"
		stroke="#039855"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M25.8787 32.1213C27.0503 33.2928 28.9497 33.2928 30.1213 32.1213C30.7071 31.5355 31 30.7677 31 30C31 29.2322 30.7071 28.4644 30.1213 27.8786C29.5392 27.2965 28.7775 27.0037 28.0146 27L26.9999 29.9999L25 30C25 30.7677 25.2929 31.5355 25.8787 32.1213Z"
		stroke="#039855"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
</svg>
