<svg
	xmlns="http://www.w3.org/2000/svg"
	width="9"
	height="16"
	viewBox="0 0 9 16"
	fill="none"
	class="mr-8"
	[ngClass]="customStyles"
>
	<path
		d="M8 15L1 8L8 1"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
