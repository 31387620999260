import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-arrow-long-left-svg',
	standalone: true,
	templateUrl: './arrow-long-left-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class ArrowLongLeftSvgComponent {
	@Input() customStyles = '';
}
