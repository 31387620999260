<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="24"
	height="25"
	viewBox="0 0 24 25"
	fill="none"
>
	<path
		d="M9 12.5L11 14.5L15 10.5M7.83474 5.19705C8.55227 5.13979 9.23346 4.85763 9.78132 4.39075C11.0598 3.30123 12.9402 3.30123 14.2187 4.39075C14.7665 4.85763 15.4477 5.13979 16.1653 5.19705C17.8397 5.33067 19.1693 6.66031 19.303 8.33474C19.3602 9.05227 19.6424 9.73346 20.1093 10.2813C21.1988 11.5598 21.1988 13.4402 20.1093 14.7187C19.6424 15.2665 19.3602 15.9477 19.303 16.6653C19.1693 18.3397 17.8397 19.6693 16.1653 19.803C15.4477 19.8602 14.7665 20.1424 14.2187 20.6093C12.9402 21.6988 11.0598 21.6988 9.78132 20.6093C9.23346 20.1424 8.55227 19.8602 7.83474 19.803C6.16031 19.6693 4.83067 18.3397 4.69705 16.6653C4.63979 15.9477 4.35763 15.2665 3.89075 14.7187C2.80123 13.4402 2.80123 11.5598 3.89075 10.2813C4.35763 9.73346 4.63979 9.05227 4.69705 8.33474C4.83067 6.66031 6.16031 5.33067 7.83474 5.19705Z"
		stroke="inherit"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
