<svg
	[ngClass]="customStyles"
	class="w-2.5 h-4"
	viewBox="0 0 9 14"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M1.5 1.16634L7.33333 6.99967L1.5 12.833"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
