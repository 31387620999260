<svg
	class="w-4 h-4 text-gray-500"
	[ngClass]="customStyles"
	aria-hidden="true"
	xmlns="http://www.w3.org/2000/svg"
	fill="currentColor"
	viewBox="0 0 24 24"
>
	<path
		fill-rule="evenodd"
		d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
		clip-rule="evenodd"
	/>
</svg>
