<svg
	width="51"
	height="24"
	viewBox="0 0 51 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g style="mix-blend-mode: multiply">
		<rect width="51" height="24" rx="12" fill="#ECFDF3" />
		<path
			d="M19.7292 6.81818V17H18.3173L13.1419 9.53267H13.0474V17H11.5112V6.81818H12.9331L18.1135 14.2955H18.2079V6.81818H19.7292ZM25.142 17.1541C24.3897 17.1541 23.7417 16.9934 23.1982 16.6719C22.6579 16.3471 22.2403 15.8913 21.9453 15.3047C21.6536 14.7147 21.5078 14.0237 21.5078 13.2315C21.5078 12.4493 21.6536 11.7599 21.9453 11.1634C22.2403 10.5668 22.6513 10.1011 23.1783 9.76633C23.7086 9.43158 24.3284 9.2642 25.0376 9.2642C25.4685 9.2642 25.8861 9.33546 26.2905 9.47798C26.6948 9.6205 27.0578 9.84422 27.3793 10.1491C27.7008 10.4541 27.9543 10.8501 28.1399 11.3374C28.3255 11.8213 28.4183 12.4096 28.4183 13.1023V13.6293H22.348V12.5156H26.9616C26.9616 12.1245 26.8821 11.7782 26.723 11.4766C26.5639 11.1716 26.3402 10.9313 26.0518 10.7557C25.7668 10.58 25.4321 10.4922 25.0476 10.4922C24.63 10.4922 24.2654 10.5949 23.9538 10.8004C23.6456 11.0026 23.407 11.2678 23.2379 11.5959C23.0722 11.9207 22.9893 12.2737 22.9893 12.6548V13.5249C22.9893 14.0353 23.0788 14.4695 23.2578 14.8274C23.4401 15.1854 23.6937 15.4588 24.0185 15.6477C24.3433 15.8333 24.7228 15.9261 25.157 15.9261C25.4387 15.9261 25.6955 15.8864 25.9276 15.8068C26.1596 15.724 26.3601 15.6013 26.5291 15.4389C26.6982 15.2765 26.8274 15.076 26.9169 14.8374L28.3239 15.0909C28.2112 15.5052 28.009 15.8681 27.7173 16.1797C27.429 16.4879 27.0661 16.7282 26.6286 16.9006C26.1944 17.0696 25.6989 17.1541 25.142 17.1541ZM31.7095 17L29.4624 9.36364H30.9986L32.495 14.9716H32.5696L34.071 9.36364H35.6072L37.0987 14.9467H37.1733L38.6598 9.36364H40.196L37.9538 17H36.4375L34.8864 11.4865H34.772L33.2209 17H31.7095Z"
			fill="#027A48"
		/>
	</g>
</svg>
