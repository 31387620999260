<svg
	width="12"
	height="13"
	viewBox="0 0 12 13"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_1818_4927)">
		<path
			d="M0.148759 1.66364L4.50428 7.40909V10.3182L7.49572 12.5V7.40909L11.8512 1.66364C11.9343 1.55539 11.9848 1.42664 11.9971 1.29187C12.0094 1.15709 11.9829 1.02163 11.9208 0.900727C11.8587 0.779822 11.7633 0.678264 11.6454 0.607472C11.5274 0.536681 11.3916 0.499464 11.2532 0.500006H0.74675C0.60837 0.499464 0.47258 0.536681 0.354649 0.607472C0.236717 0.678264 0.141321 0.779822 0.0791882 0.900727C0.0170552 1.02163 -0.0093507 1.15709 0.00293967 1.29187C0.01523 1.42664 0.0657292 1.55539 0.148759 1.66364Z"
			fill="#111928"
		/>
	</g>
	<defs>
		<clipPath id="clip0_1818_4927">
			<rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
		</clipPath>
	</defs>
</svg>
