<svg
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
	<path
		d="M11 16L18.8906 21.2604C19.5624 21.7083 20.4376 21.7083 21.1094 21.2604L29 16M13 27H27C28.1046 27 29 26.1046 29 25V15C29 13.8954 28.1046 13 27 13H13C11.8954 13 11 13.8954 11 15V25C11 26.1046 11.8954 27 13 27Z"
		stroke="#111827"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E5E7EB" />
</svg>
