<svg
	width="56"
	height="57"
	viewBox="0 0 56 57"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="4" y="4.5" width="48" height="48" rx="24" fill="#F4EBFF" />
	<path
		d="M28 22.7528V35.7528M28 22.7528C26.8321 21.9769 25.2465 21.5 23.5 21.5C21.7535 21.5 20.1679 21.9769 19 22.7528V35.7528C20.1679 34.9769 21.7535 34.5 23.5 34.5C25.2465 34.5 26.8321 34.9769 28 35.7528M28 22.7528C29.1679 21.9769 30.7535 21.5 32.5 21.5C34.2465 21.5 35.8321 21.9769 37 22.7528V35.7528C35.8321 34.9769 34.2465 34.5 32.5 34.5C30.7535 34.5 29.1679 34.9769 28 35.7528"
		stroke="#7F56D9"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<rect
		x="4"
		y="4.5"
		width="48"
		height="48"
		rx="24"
		stroke="#F9F5FF"
		stroke-width="8"
	/>
</svg>
