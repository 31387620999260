<svg
	class="w-4 h-4"
	[ngClass]="customStyles()"
	viewBox="0 0 14 14"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M12.6 1.4H11.2V0.7C11.2 0.514348 11.1263 0.336301 10.995 0.205025C10.8637 0.0737498 10.6857 0 10.5 0C10.3143 0 10.1363 0.0737498 10.005 0.205025C9.87375 0.336301 9.8 0.514348 9.8 0.7V1.4H7.7V0.7C7.7 0.514348 7.62625 0.336301 7.49497 0.205025C7.3637 0.0737498 7.18565 0 7 0C6.81435 0 6.6363 0.0737498 6.50503 0.205025C6.37375 0.336301 6.3 0.514348 6.3 0.7V1.4H4.2V0.7C4.2 0.514348 4.12625 0.336301 3.99497 0.205025C3.8637 0.0737498 3.68565 0 3.5 0C3.31435 0 3.1363 0.0737498 3.00503 0.205025C2.87375 0.336301 2.8 0.514348 2.8 0.7V1.4H1.4C1.0287 1.4 0.672601 1.5475 0.41005 1.81005C0.1475 2.0726 0 2.4287 0 2.8V12.6C0 12.9713 0.1475 13.3274 0.41005 13.5899C0.672601 13.8525 1.0287 14 1.4 14H12.6C12.9713 14 13.3274 13.8525 13.5899 13.5899C13.8525 13.3274 14 12.9713 14 12.6V2.8C14 2.4287 13.8525 2.0726 13.5899 1.81005C13.3274 1.5475 12.9713 1.4 12.6 1.4ZM2.8 2.8C2.8 2.98565 2.87375 3.1637 3.00503 3.29497C3.1363 3.42625 3.31435 3.5 3.5 3.5C3.68565 3.5 3.8637 3.42625 3.99497 3.29497C4.12625 3.1637 4.2 2.98565 4.2 2.8H6.3C6.3 2.98565 6.37375 3.1637 6.50503 3.29497C6.6363 3.42625 6.81435 3.5 7 3.5C7.18565 3.5 7.3637 3.42625 7.49497 3.29497C7.62625 3.1637 7.7 2.98565 7.7 2.8H9.8C9.8 2.98565 9.87375 3.1637 10.005 3.29497C10.1363 3.42625 10.3143 3.5 10.5 3.5C10.6857 3.5 10.8637 3.42625 10.995 3.29497C11.1263 3.1637 11.2 2.98565 11.2 2.8H12.6V4.2H1.4V2.8H2.8ZM1.4 12.6V5.6H12.6V12.6H1.4Z"
		fill="currentColor"
	/>
	<path
		d="M3.85 7H3.15C2.9567 7 2.8 7.1567 2.8 7.35V8.05C2.8 8.2433 2.9567 8.4 3.15 8.4H3.85C4.0433 8.4 4.2 8.2433 4.2 8.05V7.35C4.2 7.1567 4.0433 7 3.85 7Z"
		fill="currentColor"
	/>
	<path
		d="M3.85 9.8H3.15C2.9567 9.8 2.8 9.9567 2.8 10.15V10.85C2.8 11.0433 2.9567 11.2 3.15 11.2H3.85C4.0433 11.2 4.2 11.0433 4.2 10.85V10.15C4.2 9.9567 4.0433 9.8 3.85 9.8Z"
		fill="currentColor"
	/>
	<path
		d="M7.35 7H6.65C6.4567 7 6.3 7.1567 6.3 7.35V8.05C6.3 8.2433 6.4567 8.4 6.65 8.4H7.35C7.5433 8.4 7.7 8.2433 7.7 8.05V7.35C7.7 7.1567 7.5433 7 7.35 7Z"
		fill="currentColor"
	/>
	<path
		d="M7.35 9.8H6.65C6.4567 9.8 6.3 9.9567 6.3 10.15V10.85C6.3 11.0433 6.4567 11.2 6.65 11.2H7.35C7.5433 11.2 7.7 11.0433 7.7 10.85V10.15C7.7 9.9567 7.5433 9.8 7.35 9.8Z"
		fill="currentColor"
	/>
	<path
		d="M10.85 7H10.15C9.9567 7 9.8 7.1567 9.8 7.35V8.05C9.8 8.2433 9.9567 8.4 10.15 8.4H10.85C11.0433 8.4 11.2 8.2433 11.2 8.05V7.35C11.2 7.1567 11.0433 7 10.85 7Z"
		fill="currentColor"
	/>
	<path
		d="M10.85 9.8H10.15C9.9567 9.8 9.8 9.9567 9.8 10.15V10.85C9.8 11.0433 9.9567 11.2 10.15 11.2H10.85C11.0433 11.2 11.2 11.0433 11.2 10.85V10.15C11.2 9.9567 11.0433 9.8 10.85 9.8Z"
		fill="currentColor"
	/>
</svg>
