import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-building-office-solid-svg',
	standalone: true,
	templateUrl: './building-office-solid-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class BuildingOfficeSolidSvgComponent {
	customStyles = input('text-gray-400');
}
