<svg
	class="w-5 h-5 text-gray-500"
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	fill="currentColor"
>
	<g clip-path="url(#a)">
		<path
			fill-rule="evenodd"
			d="M13.165 7.518a3.288 3.288 0 0 0-1.554.315c-.48.23-.889.573-1.185.995v-1.32h-2.64v8.325h2.659V11.82c0-1.059.26-2.081 1.782-2.081 1.57 0 1.773 1.21 1.773 2.151v3.943h2.667v-4.45c0-2.185-.622-3.865-3.502-3.865ZM4.667 4.167c-.264 0-.522.073-.74.21a1.27 1.27 0 0 0-.492.56c-.1.229-.127.48-.076.722.052.242.179.465.365.64.186.174.424.293.683.341.258.048.526.024.77-.07.244-.095.452-.255.598-.46.147-.206.225-.448.225-.695 0-.33-.14-.649-.39-.883a1.38 1.38 0 0 0-.943-.365Z"
			clip-rule="evenodd"
		/>
		<path d="M6 7.518H3.333v8.315H6V7.518Z" />
	</g>
	<defs>
		<clipPath id="a">
			<rect width="20" height="20" fill="#fff" rx="8" />
		</clipPath>
	</defs>
</svg>
