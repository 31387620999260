<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="currentColor"
>
	<path
		d="M0.399902 1.5999C0.399902 0.937161 0.937161 0.399902 1.5999 0.399902H4.18335C4.76996 0.399902 5.27059 0.823998 5.36702 1.40262L6.2542 6.72567C6.3408 7.24531 6.07837 7.76067 5.60718 7.99626L3.74941 8.92515C5.08897 12.2539 7.74592 14.9108 11.0747 16.2504L12.0035 14.3926C12.2391 13.9214 12.7545 13.659 13.2741 13.7456L18.5972 14.6328C19.1758 14.7292 19.5999 15.2298 19.5999 15.8165V18.3999C19.5999 19.0626 19.0626 19.5999 18.3999 19.5999H15.9999C7.38426 19.5999 0.399902 12.6155 0.399902 3.9999V1.5999Z"
	/>
</svg>
