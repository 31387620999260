<svg
	class="w-4 h-4"
	[ngClass]="customStyles"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M15.7752 7.43431L14.1732 5.83429L8.5663 0.234232C8.41609 0.0842531 8.21239 0 8 0C7.78761 0 7.58391 0.0842531 7.4337 0.234232L1.82681 5.83429L0.224846 7.43431C0.07894 7.58519 -0.0017947 7.78728 3.02793e-05 7.99704C0.00185526 8.20679 0.0860939 8.40745 0.234603 8.55578C0.383112 8.7041 0.584009 8.78824 0.794024 8.79006C1.00404 8.79188 1.20637 8.71125 1.35744 8.56552L1.59213 8.33112V14.4C1.59213 14.8243 1.7609 15.2313 2.06133 15.5314C2.36176 15.8314 2.76923 16 3.19409 16H5.59705C5.80948 16 6.01322 15.9157 6.16343 15.7657C6.31364 15.6157 6.39803 15.4122 6.39803 15.2V12C6.39803 11.7878 6.48242 11.5843 6.63263 11.4343C6.78285 11.2842 6.98658 11.1999 7.19902 11.1999H8.80098C9.01342 11.1999 9.21715 11.2842 9.36737 11.4343C9.51758 11.5843 9.60197 11.7878 9.60197 12V15.2C9.60197 15.4122 9.68636 15.6157 9.83657 15.7657C9.98678 15.9157 10.1905 16 10.403 16H12.8059C13.2308 16 13.6382 15.8314 13.9387 15.5314C14.2391 15.2313 14.4079 14.8243 14.4079 14.4V8.33112L14.6426 8.56552C14.7936 8.71125 14.996 8.79188 15.206 8.79006C15.416 8.78824 15.6169 8.7041 15.7654 8.55578C15.9139 8.40745 15.9981 8.20679 16 7.99704C16.0018 7.78728 15.9211 7.58519 15.7752 7.43431Z"
		fill="#9CA3AF"
	/>
</svg>
