import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-ringed-right-arrow-svg',
	standalone: true,
	imports: [],
	templateUrl: './ringed-right-arrow-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RingedRightArrowSvgComponent {}
