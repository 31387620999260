import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-book-solid-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './book-solid-svg.component.html',
})
export class BookSolidSvgComponent {
	@Input() customStyles = '';
}
