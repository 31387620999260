<svg
	[ngClass]="customStyles"
	class="w-5 h-5"
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="2"
		d="M7 16l-4-4m0 0l4-4m-4 4h18"
	></path>
</svg>
