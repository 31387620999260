<svg
	xmlns="http://www.w3.org/2000/svg"
	width="10"
	height="10"
	viewBox="0 0 10 10"
	fill="none"
>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M6.05093 0.220298C6.21726 0.264471 6.35662 0.37794 6.43359 0.53187C6.72372 1.11214 6.94026 1.46433 7.15811 1.74987C7.37863 2.03891 7.61189 2.27553 7.96672 2.63036C8.78671 3.45035 9.19688 4.52627 9.19687 5.60022C9.19687 6.67416 8.78671 7.75008 7.96672 8.57006C6.32652 10.2103 3.66723 10.2103 2.02703 8.57006C1.20704 7.75008 0.796876 6.67416 0.796875 5.60022C0.796873 4.52627 1.20704 3.45035 2.02703 2.63036C2.19863 2.45876 2.4567 2.40743 2.6809 2.5003C2.90511 2.59317 3.05129 2.81195 3.05129 3.05463C3.05129 3.72651 3.09325 4.23847 3.28976 4.64682C3.39782 4.87136 3.56638 5.09089 3.8569 5.28678C3.92628 4.65032 4.05339 3.87488 4.22512 3.13886C4.36034 2.55937 4.52851 1.98083 4.72867 1.49839C4.82879 1.25709 4.94201 1.02732 5.07059 0.828939C5.19574 0.635841 5.35655 0.439343 5.56411 0.300968C5.7073 0.205505 5.8846 0.176126 6.05093 0.220298ZM6.26967 8.07299C5.56672 8.77593 4.42703 8.77593 3.72408 8.07299C3.37261 7.72152 3.19687 7.26086 3.19687 6.8002C3.19687 6.8002 3.72408 7.10019 4.6969 7.10019C4.6969 6.50019 4.9969 4.70019 5.4469 4.4002C5.7469 5.00019 5.9182 5.17594 6.26967 5.52741C6.62114 5.87888 6.79688 6.33954 6.79687 6.8002C6.79687 7.26086 6.62114 7.72152 6.26967 8.07299Z"
		fill="#065F46"
	/>
</svg>
