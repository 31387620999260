<svg
	class="w-6 h-6 stroke-gray-500"
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 24 25"
	fill="none"
>
	<path
		d="M5 3.5V7.5M3 5.5H7M6 17.5V21.5M4 19.5H8M13 3.5L15.2857 10.3571L21 12.5L15.2857 14.6429L13 21.5L10.7143 14.6429L5 12.5L10.7143 10.3571L13 3.5Z"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
