<svg
	xmlns="http://www.w3.org/2000/svg"
	class="h-9 w-9 text-uc-green-700 mr-2.5"
	[ngClass]="customStyles"
	viewBox="0 0 20 20"
	fill="currentColor"
>
	<path
		fill-rule="evenodd"
		d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
		clip-rule="evenodd"
	/>
</svg>
