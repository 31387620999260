import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-clock-solid-svg',
	standalone: true,
	templateUrl: './clock-solid-svg.component.html',
	imports: [NgClass],
})
export class ClockSolidSvgComponent {
	@Input() customStyles = '';
}
