<svg
	width="56"
	height="56"
	viewBox="0 0 56 56"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
	<path
		d="M28.0015 25V27M28.0015 31H28.0115M21.0733 35H34.9297C36.4693 35 37.4316 33.3333 36.6618 32L29.7336 20C28.9637 18.6667 27.0392 18.6667 26.2694 20L19.3412 32C18.5714 33.3333 19.5337 35 21.0733 35Z"
		stroke="#DC6803"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" stroke-width="8" />
</svg>
