<svg
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
		fill="#0A66C2"
	/>
	<path
		d="M29.376 9H10.624C10.1933 9 9.78023 9.1711 9.47567 9.47567C9.1711 9.78023 9 10.1933 9 10.624V29.376C9 29.8067 9.1711 30.2198 9.47567 30.5243C9.78023 30.8289 10.1933 31 10.624 31H29.376C29.8067 31 30.2198 30.8289 30.5243 30.5243C30.8289 30.2198 31 29.8067 31 29.376V10.624C31 10.1933 30.8289 9.78023 30.5243 9.47567C30.2198 9.1711 29.8067 9 29.376 9ZM15.5572 27.7413H12.2496V17.2347H15.5572V27.7413ZM13.9011 15.7788C13.5259 15.7766 13.1598 15.6634 12.8488 15.4534C12.5379 15.2434 12.2962 14.946 12.1541 14.5987C12.0121 14.2514 11.9761 13.8699 12.0507 13.5022C12.1253 13.1345 12.3072 12.7971 12.5733 12.5326C12.8395 12.2682 13.178 12.0885 13.5462 12.0163C13.9144 11.944 14.2957 11.9825 14.642 12.1267C14.9884 12.271 15.2842 12.5147 15.4923 12.8269C15.7003 13.1392 15.8111 13.5061 15.8108 13.8813C15.8144 14.1324 15.7673 14.3818 15.6724 14.6144C15.5776 14.847 15.4368 15.0582 15.2586 15.2352C15.0804 15.4123 14.8684 15.5517 14.6351 15.6451C14.4019 15.7384 14.1523 15.7839 13.9011 15.7788ZM27.7489 27.7504H24.4428V22.0106C24.4428 20.3178 23.7232 19.7953 22.7943 19.7953C21.8135 19.7953 20.851 20.5347 20.851 22.0533V27.7504H17.5433V17.2424H20.7242V18.6983H20.7669C21.0863 18.0521 22.2046 16.9475 23.9111 16.9475C25.7567 16.9475 27.7504 18.0429 27.7504 21.2513L27.7489 27.7504Z"
		fill="white"
	/>
</svg>
