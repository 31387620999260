<svg
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
		fill="#BD081C"
	/>
	<path
		d="M19.9951 8C13.3608 8 8 13.3706 8 19.9951C8 25.0794 11.1592 29.4233 15.6216 31.1707C15.513 30.2229 15.4241 28.7618 15.661 27.7252C15.8782 26.7873 17.0629 21.7622 17.0629 21.7622C17.0629 21.7622 16.7075 21.0416 16.7075 19.9852C16.7075 18.3167 17.675 17.0728 18.8795 17.0728C19.9062 17.0728 20.3998 17.8429 20.3998 18.761C20.3998 19.7877 19.7482 21.3278 19.4027 22.7594C19.1164 23.9539 20.0049 24.9313 21.1798 24.9313C23.3122 24.9313 24.9511 22.6804 24.9511 19.4422C24.9511 16.5693 22.8877 14.5652 19.9358 14.5652C16.52 14.5652 14.5158 17.1222 14.5158 19.768C14.5158 20.7947 14.9107 21.9004 15.4044 22.5027C15.5031 22.6211 15.513 22.7297 15.4833 22.8482C15.3945 23.2234 15.1872 24.0428 15.1477 24.2106C15.0983 24.4278 14.97 24.4772 14.7429 24.3686C13.262 23.6577 12.334 21.4661 12.334 19.7088C12.334 15.9276 15.0786 12.4525 20.2616 12.4525C24.4179 12.4525 27.6561 15.4142 27.6561 19.383C27.6561 23.5195 25.0498 26.8466 21.4364 26.8466C20.2221 26.8466 19.0769 26.2147 18.6919 25.4644C18.6919 25.4644 18.0897 27.7548 17.9416 28.3176C17.675 29.3641 16.9445 30.6672 16.4508 31.4669C17.5763 31.8124 18.761 32 20.0049 32C26.6294 32 32 26.6294 32 20.0049C31.9901 13.3706 26.6195 8 19.9951 8Z"
		fill="white"
	/>
</svg>
