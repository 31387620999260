import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-arrow-right-v2-svg',
	standalone: true,
	imports: [],
	templateUrl: './arrow-right-v2-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowRightV2SvgComponent {}
