import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-newspaper-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './newspaper-svg.component.html',
})
export class NewspaperSvgComponent {
	@Input() customStyles = '';
}
