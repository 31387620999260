import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-right-arrow-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './right-arrow-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightArrowSvgComponent {
	@Input() customStyles = '';
}
