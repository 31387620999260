<svg
	[ngClass]="customStyles"
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#F9FAFB" />
	<path
		d="M20 19C21.6569 19 23 17.6569 23 16C23 14.3431 21.6569 13 20 13C18.3431 13 17 14.3431 17 16C17 17.6569 18.3431 19 20 19Z"
		fill="#6B7280"
	/>
	<path
		d="M13 28C13 24.134 16.134 21 20 21C23.866 21 27 24.134 27 28H13Z"
		fill="#6B7280"
	/>
	<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E5E7EB" />
</svg>
