import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-trash-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './trash-svg.component.html',
})
export class TrashSvgComponent {
	@Input() customStyles = '';
}
