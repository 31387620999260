<svg
	class="text-uc-blue-900"
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
>
	<path
		d="M5.33203 6.00016L7.9987 3.3335L10.6654 6.00016M10.6654 10.0002L7.9987 12.6668L5.33203 10.0002"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
