<svg
	class="cursor-grab"
	width="24"
	height="25"
	viewBox="0 0 24 25"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M16 6.5C16.5523 6.5 17 6.05228 17 5.5C17 4.94772 16.5523 4.5 16 4.5C15.4477 4.5 15 4.94772 15 5.5C15 6.05228 15.4477 6.5 16 6.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M16 13.5C16.5523 13.5 17 13.0523 17 12.5C17 11.9477 16.5523 11.5 16 11.5C15.4477 11.5 15 11.9477 15 12.5C15 13.0523 15.4477 13.5 16 13.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M16 20.5C16.5523 20.5 17 20.0523 17 19.5C17 18.9477 16.5523 18.5 16 18.5C15.4477 18.5 15 18.9477 15 19.5C15 20.0523 15.4477 20.5 16 20.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M9 6.5C9.55228 6.5 10 6.05228 10 5.5C10 4.94772 9.55228 4.5 9 4.5C8.44772 4.5 8 4.94772 8 5.5C8 6.05228 8.44772 6.5 9 6.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M9 13.5C9.55228 13.5 10 13.0523 10 12.5C10 11.9477 9.55228 11.5 9 11.5C8.44772 11.5 8 11.9477 8 12.5C8 13.0523 8.44772 13.5 9 13.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M9 20.5C9.55228 20.5 10 20.0523 10 19.5C10 18.9477 9.55228 18.5 9 18.5C8.44772 18.5 8 18.9477 8 19.5C8 20.0523 8.44772 20.5 9 20.5Z"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
