import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-refresh-svg',
	standalone: true,
	imports: [],
	templateUrl: './refresh-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshSvgComponent {}
