import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-speech-bubble-filled-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './speech-bubble-filled-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechBubbleFilledSvgComponent {
	@Input() customStyles!: string;
}
