<svg
	class="w-8 h-8 text-gray-500"
	[ngClass]="customStyles"
	fill="currentColor"
	xmlns="http://www.w3.org/2000/svg"
	data-name="Layer 2"
	viewBox="0 0 32 32"
>
	<path
		d="M28.481,26.751A11.366,11.366,0,0,1,26,19.678V13A11,11,0,0,0,4,13v7A10.011,10.011,0,0,0,14,30H26.919a2,2,0,0,0,1.562-3.249ZM11,14a2,2,0,1,1,2-2A2,2,0,0,1,11,14Zm6,7a2,2,0,0,1-4,0V17a2,2,0,0,1,4,0Zm2-7a2,2,0,1,1,2-2A2,2,0,0,1,19,14Z"
	/>
</svg>
