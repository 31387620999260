<svg
	width="32"
	height="32"
	viewBox="0 0 32 32"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect width="32" height="32" rx="16" fill="#E0F2FE" />
	<path
		d="M10 16L14 20L22 12"
		stroke="#36539C"
		stroke-width="2.66667"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
