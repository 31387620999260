import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-exclamation-mark-round-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './exclamation-mark-round-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclamationMarkRoundSvgComponent {
	@Input() customStyles = '';
}
