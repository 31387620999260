import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-sort-arrows-svg',
	standalone: true,
	imports: [NgClass],
	templateUrl: './sort-arrows-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortArrowsSvgComponent {
	customStyles = input<string>('');
}
