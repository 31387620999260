import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-pound-svg',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './pound-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoundSvgComponent {
	@Input() customStyles = 'h-8 w-8 text-gray-500';
}
