<svg
	[ngClass]="customStyles"
	xmlns="http://www.w3.org/2000/svg"
	width="24"
	height="25"
	viewBox="0 0 24 25"
	fill="none"
>
	<path
		d="M11 5.5H6C4.89543 5.5 4 6.39543 4 7.5V18.5C4 19.6046 4.89543 20.5 6 20.5H17C18.1046 20.5 19 19.6046 19 18.5V13.5M17.5858 4.08579C18.3668 3.30474 19.6332 3.30474 20.4142 4.08579C21.1953 4.86683 21.1953 6.13316 20.4142 6.91421L11.8284 15.5H9L9 12.6716L17.5858 4.08579Z"
		stroke="inherit"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
