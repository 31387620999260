<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M8.33333 11.6667L4.36339 11.6667C3.12442 11.6667 2.31859 10.3628 2.87268 9.25464L5.78934 3.42131C6.07166 2.85667 6.64877 2.5 7.28006 2.5L10.6282 2.5C10.7644 2.5 10.9002 2.51671 11.0324 2.54976L14.1667 3.33333M8.33333 11.6667V15.8333C8.33333 16.7538 9.07953 17.5 10 17.5H10.0796C10.4959 17.5 10.8333 17.1625 10.8333 16.7462C10.8333 16.151 11.0095 15.569 11.3397 15.0738L14.1667 10.8333L14.1667 3.33333M8.33333 11.6667H10M14.1667 3.33333H15.8333C16.7538 3.33333 17.5 4.07952 17.5 5V10C17.5 10.9205 16.7538 11.6667 15.8333 11.6667H13.75"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
