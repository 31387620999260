<svg
	width="40"
	height="40"
	viewBox="0 0 40 40"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M40 20C40 8.95317 31.0468 0 20 0C8.95317 0 0 8.95317 0 20C0 29.9844 7.3125 38.2578 16.875 39.7578V25.7813H11.7968V20H16.875V15.5938C16.875 10.582 19.8594 7.8125 24.4297 7.8125C26.6172 7.8125 28.9062 8.20312 28.9062 8.20312V13.125H26.3828C23.8984 13.125 23.125 14.668 23.125 16.25V20H28.6718L27.7852 25.7813H23.125V39.7578C32.6875 38.2578 40 29.9844 40 20Z"
		fill="#1877F2"
	/>
</svg>
