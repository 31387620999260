<svg
	[ngClass]="customStyles"
	fill="currentColor"
	xmlns="http://www.w3.org/2000/svg"
	data-name="Layer 2"
	viewBox="0 0 32 32"
>
	<path
		d="M19,7h-.1a3.686,3.686,0,0,1,.441-1.765,2.168,2.168,0,0,0-.053-2.155,2.222,2.222,0,0,0-3.211-.657A7.439,7.439,0,0,0,13.143,7H13a11,11,0,0,0,0,22h6A11,11,0,0,0,19,7Zm1.091,5.214a.5.5,0,0,1,.819,0l2.022,3.117a.442.442,0,0,1-.409.669H18.478a.442.442,0,0,1-.409-.669ZM16.7,19H15.3a.3.3,0,0,1-.266-.431l.7-1.405a.3.3,0,0,1,.532,0l.7,1.405A.3.3,0,0,1,16.7,19ZM9.069,15.331l2.022-3.117a.5.5,0,0,1,.819,0l2.022,3.117a.442.442,0,0,1-.409.669H9.478A.442.442,0,0,1,9.069,15.331Zm14.54,6.2-1.242,2.335a1,1,0,0,1-1.494.322L19.594,23.2,18.643,24.5a1,1,0,0,1-.739.408l-.069,0a1,1,0,0,1-.718-.3L16,23.456l-1.125,1.156a1.02,1.02,0,0,1-.788.3,1,1,0,0,1-.737-.409L12.4,23.2l-1.276.986a1,1,0,0,1-1.5-.323L8.391,21.527a1,1,0,0,1,1.768-.937l.681,1.286,1.151-.89a1,1,0,0,1,1.42.2l.856,1.177,1.016-1.044a1,1,0,0,1,.717-.3h0a1,1,0,0,1,.717.3l1.008,1.041.857-1.176a1,1,0,0,1,1.419-.2l1.154.891.686-1.289a1,1,0,1,1,1.766.938Z"
	/>
</svg>
