<svg
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_2_14510)">
		<path
			d="M11.0689 6.06091L11.0649 6.06651L5.35497 11.777L7.06626 13.4876L12.7809 7.77317L11.0689 6.06091Z"
			fill="#9CA3AF"
		/>
		<path
			d="M2.51163 8.93334L4.22292 10.6456L9.9336 4.93514L9.9392 4.93114L8.22631 3.21808L2.51163 8.93334Z"
			fill="#9CA3AF"
		/>
		<path
			d="M1.59639 10.2807L0.0411104 14.9462C-0.0548943 15.2335 0.0203093 15.5511 0.23472 15.7648C0.386727 15.9176 0.591538 16 0.800348 16C0.885152 16 0.970756 15.9864 1.05316 15.9584L5.71819 14.403L1.59639 10.2807Z"
			fill="#9CA3AF"
		/>
		<path
			d="M15.0579 0.940941C13.8026 -0.313647 11.7593 -0.313647 10.504 0.940941L9.35837 2.08671L13.913 6.6418L15.0587 5.49602C16.3139 4.24064 16.3139 2.19633 15.0579 0.940941Z"
			fill="#9CA3AF"
		/>
	</g>
	<defs>
		<clipPath id="clip0_2_14510">
			<rect width="16" height="16" fill="white" />
		</clipPath>
	</defs>
</svg>
